import { inject, Injectable, signal, Signal } from '@angular/core';
import { LoadingService } from './loading.service';
import {
  Auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth = inject(Auth);
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private loadingService = inject(LoadingService);

  private loggedIn$ = signal(false);

  constructor() {
    this.auth.onAuthStateChanged(() =>
      this.loggedIn$.set(!!this.auth.currentUser),
    );
  }

  /**
   * Receive loggedIn status
   */
  get loggedIn(): Signal<boolean> {
    return this.loggedIn$;
  }

  /**
   * Receive user
   */
  get user(): any {
    return this.auth.currentUser;
  }

  /**
   * Sign up to firebase with email and password
   * @param email
   * @param password
   * @param rememberMe seeing signIn logic
   */
  async signUp(email: string, password: string, rememberMe: boolean) {
    const persistence = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;

    this.loadingService.loading = true;
    this.auth
      .setPersistence(persistence)
      .then(() => {
        createUserWithEmailAndPassword(this.auth, email, password)
          .then(() => this.router.navigate(['/']))
          .catch((error) => {
            console.error(error);
            const errorCode = error.code;
            switch (errorCode) {
              case 'auth/email-already-in-use':
                this.toastr.error('E-Mail existiert bereits');
                break;
              case 'auth/invalid-email':
                this.toastr.error('E-Mail ungültig');
                break;
              default:
                this.toastr.error('Fehler! Bitte versuche es später erneut');
                break;
            }
          })
          .finally(async () => {
            this.router.navigate(['/']);
            this.loadingService.loading = false;
            await sendEmailVerification(this.auth.currentUser!);
          });
      })
      .catch((error) => {
        console.error(error);
        this.toastr.error('Fehler! Bitte versuche es später erneut');
        this.loadingService.loading = false;
      });
  }

  /**
   * Sign in to firebase with email and password
   * @param email
   * @param password
   * @param rememberMe Sets the persistence of the auth state. If true, the auth state persists even after the browser is closed.
   */
  async signIn(email: string, password: string, rememberMe: boolean) {
    const persistence = rememberMe
      ? browserLocalPersistence
      : browserSessionPersistence;

    this.loadingService.loading = true;
    this.auth
      .setPersistence(persistence)
      .then(() => {
        signInWithEmailAndPassword(this.auth, email, password)
          .then(() => this.router.navigate(['/']))
          .catch((error) => {
            console.error(error);
            const errorCode = error.code;
            switch (errorCode) {
              case 'auth/invalid-credential':
                this.toastr.error(
                  'E-Mail/Passwort falsch - hast du bereits ein Konto?',
                );
                this.router.navigate(['/auth/login']);
                break;
              default:
                this.toastr.error('Fehler! Bitte versuche es später erneut');
                break;
            }
          })
          .finally(() => {
            this.router.navigate(['/']);
            this.loadingService.loading = false;
          });
      })
      .catch((error) => {
        console.error(error);
        this.toastr.error('Fehler! Bitte versuche es später erneut');
        this.loadingService.loading = false;
      });
  }

  /**
   * Sign out from firebase
   */
  signOut() {
    this.loadingService.loading = true;
    signOut(this.auth)
      .then(() => {
        this.router
          .navigate(['/auth'])
          .then(() => (this.loadingService.loading = false));
      })
      .catch((error: any) => {
        console.error(error);
        this.toastr.error(
          'Abmeldung fehlgeschlagen, bitte versuchen Sie es erneut',
        );
      });
  }

  /**
   * This sends a verification email to the user manually. This is not used after sign up,
   * but when the user wants to resend the verification email.
   */
  sendVerificationEmail() {
    this.loadingService.loading = true;
    sendEmailVerification(this.auth.currentUser!)
      .then(() => this.toastr.info('E-Mail zum Bestätigen gesendet'))
      .catch(async (error) => {
        console.error(error);
        const errorCode = error.code;
        switch (errorCode) {
          case 'auth/too-many-requests':
            this.toastr.error(
              'Zu viele Anfragen - bitte versuche es später erneut',
            );
            await this.router.navigate(['/auth/login']);
            break;
          default:
            this.toastr.error('Fehler! Bitte versuche es später erneut');
            break;
        }
      })
      .finally(() => (this.loadingService.loading = false));
  }

  deleteUser() {
    this.loadingService.loading = true;
    if (!this.auth.currentUser) {
      return;
    }
    this.auth.currentUser
      .delete()
      .then(async () => {
        this.toastr.success('Benutzer erfolgreich gelöscht');
        await this.router.navigate(['/auth']);
      })
      .catch(async (error) => {
        console.error(error);
        const errorCode = error.code;
        switch (errorCode) {
          case 'auth/requires-recent-login':
            this.toastr.error(
              'Löschen des Benutzers erfordert erneute Anmeldung',
            );
            break;
          default:
            this.toastr.error('Fehler! Bitte versuche es später erneut');
            break;
        }
      })
      .finally(() => (this.loadingService.loading = false));
  }

  /**
   * Sends a password reset email to the user
   * @param email
   */
  async resetPassword(email: string) {
    this.loadingService.loading = true;
    try {
      await sendPasswordResetEmail(this.auth, email);
      this.toastr.info('E-Mail zum Zurücksetzen des Passworts gesendet');
    } catch (error) {
      this.toastr.error('Fehler! Bitte versuche es später erneut');
    } finally {
      this.router
        .navigate(['/auth'])
        .then(() => (this.loadingService.loading = false));
    }
  }
}
