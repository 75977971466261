import { computed, Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingInstances$ = signal(0);
  private loading$ = computed(() => this.loadingInstances$() > 0);

  get loading(): Signal<boolean> {
    return this.loading$;
  }

  set loading(value: boolean) {
    value ? this.addLoadingInstance() : this.removeLoadingInstance();
  }

  private addLoadingInstance() {
    this.loadingInstances$.set(this.loadingInstances$() + 1);
  }

  private removeLoadingInstance() {
    this.loadingInstances$() > 0 &&
      this.loadingInstances$.set(this.loadingInstances$() - 1);
  }
}
