export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAM5j2WlgrpjejjDFqmeg1ZEXRy9TkRcd0',
    authDomain: 'sirlandlord-dev.firebaseapp.com',
    databaseURL:
      'https://sirlandlord-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'sirlandlord-dev',
    storageBucket: 'sirlandlord-dev.appspot.com',
    messagingSenderId: '439287548463',
    appId: '1:439287548463:web:5cccb96147b75bf04914da',
  },
  domains: {
    landingPage: 'https://dev.sirlandlord.at',
    api: 'https://dev.api.sirlandlord.at',
  },
};
